import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Header, Item } from "semantic-ui-react"

const IndexPage = ({data}) => {
  return (
  <Layout>
    <SEO title="Home" />
    <div>
        <Header as='h1'>
          Blog
          <Header.Subheader>{data.allMarkdownRemark.totalCount} Posts</Header.Subheader>
        </Header>

        <Item.Group unstackable>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Item key={node.id}>
            <Item.Content>
              <Item.Header as={Link} to={node.fields.slug}>{node.frontmatter.title}</Item.Header>
              <Item.Meta>{node.frontmatter.date}</Item.Meta>
              <Item.Description>{node.excerpt}</Item.Description>
              <Item.Extra><Link to={node.fields.slug}>Read</Link></Item.Extra>
            </Item.Content>
          </Item>
        ))}
        </Item.Group>
      </div>

  </Layout>
)}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {published: {ne: false}}}, sort: {fields: frontmatter___date, order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`